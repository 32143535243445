import {
  Description,
  CardGrid,
  CustomSolutions,
  ContactUs,
} from '../../components'
import CookieDialog from '../../components/CookieDialog'
import './index.css'

export const SenzorixLandingPage = () => {
  return (
    <div className="container">
      <CookieDialog />
      <div className="main-section">
        <Description />
        <CardGrid />
        <CustomSolutions />
      </div>
      <ContactUs />
    </div>
  )
}
