const sendEmail = async (payload, token) => {
  const response = await fetch(
    `https://mailer.senzorix.co/senzorix/send-email`,
    {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }
  )
  return response
}

export default sendEmail
