import * as React from 'react'
import { styled } from '@mui/material/styles'
import Checkbox from '@mui/material/Checkbox'
import {
  checkbox_icon_color,
  checkbox_selected_bg_icon,
} from '../../util/colors'

const BpIcon = styled('span')(() => ({
  borderRadius: 3,
  padding: '0rem',
  width: '0.625rem',
  height: '0.625rem',
  backgroundColor: checkbox_icon_color,
}))

const BpCheckedIcon = styled(BpIcon)({
  '&:before': {
    display: 'block',
    padding: '0rem',
    width: '0.625rem',
    height: '0.625rem',
    backgroundRepeat: 'no-repeat',
    borderRadius: '3px',
    backgroundPosition: 'center center',
    backgroundColor: checkbox_selected_bg_icon,
    backgroundImage:
      "url(\"data:image/svg+xml,<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'><g id='Checkbox Icon - Checked'><rect id='Checkbox Icon' width='10' height='10' rx='2' fill='%23FF4D4D'/><path id='Vector 1' d='M3 5.5L4.36364 7L8 3' stroke='%23DEDEDE'/></g></svg>\")",
    content: '""',
  },
})

const BpErrorIcon = styled(BpIcon)({
  '&:before': {
    display: 'block',
    padding: '0rem',
    width: '0.625rem',
    height: '0.625rem',
    backgroundRepeat: 'no-repeat',
    borderRadius: '3px',
    backgroundPosition: 'center center',
    backgroundColor: checkbox_selected_bg_icon,
    backgroundImage:
      "url(\"data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='Icon' d='M3.70708 3L5.60353 1.10355L4.89642 0.396439L2.99997 2.29289L1.10353 0.396453L0.396423 1.10356L2.29286 3L0.396423 4.89644L1.10353 5.60355L2.99997 3.70711L4.89642 5.60356L5.60353 4.89645L3.70708 3Z' fill='%23DEDEDE'/%3E%3C/svg%3E%0A\")",
    content: '""',
  },
})

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
        padding: '0rem',
      }}
      disableRipple
      color="default"
      icon={<BpIcon />}
      checkedIcon={props.error ? <BpErrorIcon /> : <BpCheckedIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  )
}

export default function CustomCheckbox(props) {
  return <BpCheckbox {...props} />
}
