const applyGoogleAnalytics = () => {
  const e = document.createElement('script')
  e.async = 1
  e.src = 'https://www.googletagmanager.com/gtag/js?id=G-J08C8RZC04'
  document.head.appendChild(e)
  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  gtag('js', new Date())

  gtag('config', 'G-J08C8RZC04')
}

const applyHotjar = () => {
  ;(function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        ;(h.hj.q = h.hj.q || []).push(arguments)
      }
    h._hjSettings = { hjid: 3732494, hjsv: 6 }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script')
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}

export { applyGoogleAnalytics, applyHotjar }
