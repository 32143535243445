import { useEffect, forwardRef } from 'react'
import { useLocation } from 'react-router-dom'
import { changeLanguage } from '../i18n.js'

//useNavigate's default behaviour isnt to stroll to the top of the page so this function is here to help
export const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export const handleLanguage = ({ lang, setEnglishActive }) => {
  localStorage.setItem('localisation', lang)
  setEnglishActive(lang === 'en' ? true : false)
  changeLanguage(lang)
}

export const getLanguage = ({ setEnglishActive }) => {
  const currentlySetLanguage = localStorage.getItem('localisation')

  //default
  if (!currentlySetLanguage) handleLanguage({ lang: 'en', setEnglishActive })
  else
    handleLanguage({
      lang: currentlySetLanguage,
      setEnglishActive,
    })
}

// eslint-disable-next-line no-empty-pattern
export const ScrollToAnchor = forwardRef(({}, ref) => {
  const location = useLocation()

  useEffect(() => {
    if (ref.current && document.getElementById(ref.current)) {
      setTimeout(() => {
        document
          .getElementById(ref.current)
          .scrollIntoView({ behavior: 'smooth' })
      }, 25)
    }
  }, [location, ref])

  return null
})

export const validatePayload = (payload) => {
  for (const value of Object.values(payload)) {
    if (!value) {
      return false
    }
  }
  return true
}
