import * as React from 'react'
import { useTranslation } from 'react-i18next'
import './custom_input.css'

const CustomInput = ({
  errorMessage,
  textarea,
  value,
  name,
  onChange,
  label,
}) => {
  const { t } = useTranslation('translations')
  return (
    <div className={`custom-input-container ${textarea ? 'textarea' : ''}`}>
      <div className="custom-input-error-message">{errorMessage}</div>
      <div className={`custom-input-label`}>{label}</div>
      {textarea ? (
        <textarea
          className={`custom-input ${errorMessage ? 'error' : ''}`}
          name={name}
          value={value}
          onChange={(e) => onChange(e)}
        />
      ) : (
        <input
          className={`custom-input ${errorMessage ? 'error' : ''}`}
          name={name}
          value={value}
          onChange={(e) => onChange(e)}
        />
      )}
    </div>
  )
}

export default CustomInput
