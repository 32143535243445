import * as React from 'react'
import CustomCheckbox from '../CustomCheckbox'
import './custom_button.css'

const CustomButton = ({ onClick, checked, children }) => {
  return (
    <div className="custom-button-container" onClick={() => onClick()}>
      <CustomCheckbox checked={checked} />
      {children}
    </div>
  )
}

export default CustomButton
