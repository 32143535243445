import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import CustomInput from '../CustomInput'
import CustomButton from '../CustomButton'
import CustomCheckbox from '../CustomCheckbox'
import { ReactComponent as SendIcon } from '../../assets/SendPaperplaneIcon.svg'
import sendEmail from '../../api/sendEmail'
import ReCAPTCHA from 'react-google-recaptcha'
import { validatePayload } from '../../util/util'
import { Dialog, DialogTitle, DialogContent } from '@mui/material'
import './contact_us.css'

const ContactUs = () => {
  const { t } = useTranslation('translations')
  const [formData, setFormData] = useState({
    full_name: '',
    company_name: '',
    email: '',
    subject: '',
    message: '',
  })
  const [validation, setValidation] = useState(false)
  const [displayDialog, setDisplayDialog] = useState(false)
  const [recaphaStatus, setRecapchaStatus] = useState(false)
  const [pointsOfInterest, setPointsOfInterests] = useState([])
  const [deliverStatus, setDeliverStatus] = useState('')
  const recaptcha = useRef()
  const [mQuery, setMQuery] = useState({
    matches: window.innerWidth >= 910 ? true : false,
  })

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth >= 910) {
        setMQuery(true)
      } else {
        setMQuery(false)
      }
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  const handlePointsOfInterests = (point) => {
    if (pointsOfInterest.length && pointsOfInterest.includes(point)) {
      let temp = [...pointsOfInterest].filter((e) => e !== point)
      setPointsOfInterests(temp)
    } else {
      let temp = [...pointsOfInterest, point]
      setPointsOfInterests(temp)
    }
  }

  const checkedStatus = (point) => {
    if (pointsOfInterest.includes(point)) {
      return true
    }
    return false
  }

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const sendMail = async () => {
    setValidation(true)
    let payload = { ...formData, points_of_interest: pointsOfInterest }
    const validateBody = validatePayload(formData)
    if (validateBody) {
      if (recaphaStatus) {
        submit(payload)
      } else {
        setDisplayDialog(true)
      }
    }
  }

  const submit = async () => {
    let payload = { ...formData, points_of_interest: pointsOfInterest }
    const captchaValue = recaptcha.current.getValue()
    if (captchaValue) {
      setDisplayDialog(false)
      setRecapchaStatus(false)
      setDeliverStatus('sending')
      const response = await sendEmail(payload, captchaValue)
      if (response.status === 200) {
        setDeliverStatus('delivered')
      } else {
        setDeliverStatus('error')
      }
      setTimeout(() => setDeliverStatus(''), 2000)
    }
  }

  const renderButtonText = () => {
    if (deliverStatus === 'sending') {
      return t('contact.sending')
    }
    if (deliverStatus === 'delivered') {
      return t('contact.delivered')
    }
    if (deliverStatus === 'error') {
      return t('contact.error')
    }
    return t('contact.send_now')
  }

  const renderButtonIcon = () => {
    if (deliverStatus === 'delivered') {
      return <CustomCheckbox checked />
    }
    if (deliverStatus === 'error') {
      return <CustomCheckbox checked error />
    }
    return <SendIcon className="contact-us-send-icon" />
  }

  const renderDialog = () => {
    return (
      <Dialog
        PaperProps={{
          style: {
            margin: '0',
          },
        }}
        open={displayDialog}
      >
        <DialogTitle className="contact-us-dialog-title">
          {t('recaptcha_dialog.not_robot_confirmation')}
        </DialogTitle>
        <DialogContent>
          <div className="contact-us-recapcha-container">
            <ReCAPTCHA
              data-theme="dark"
              size="normal"
              sitekey={process.env.REACT_APP_RECAPCHA_PUBLIC}
              render="explicit"
              ref={recaptcha}
              onChange={() => setRecapchaStatus(true)}
              onErrored={() => setRecapchaStatus(false)}
            />
            <div
              className={`contact-us-confirm-button`}
              onClick={() => submit()}
            >
              {t('recaptcha_dialog.confirm')}
            </div>
            <div
              className={`contact-us-cancel-button`}
              onClick={() => setDisplayDialog(false)}
            >
              {t('recaptcha_dialog.cancel')}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <div id="contact" className="contact-us-main-container">
      {displayDialog && renderDialog()}
      <div className="contact-us-container">
        <div className="contact-us-title">{t('contact.title')}</div>
        <div className="contact-us-split-wrapper">
          <div className="contact-us-input-wrapper">
            <CustomInput
              errorMessage={
                !formData.full_name && validation
                  ? t('contact.error_message_required')
                  : ''
              }
              name={'full_name'}
              onChange={(e) => handleChange(e)}
              label={t('contact.full_name')}
            />
            <CustomInput
              errorMessage={
                !formData.company_name && validation
                  ? t('contact.error_message_required')
                  : ''
              }
              name={'company_name'}
              onChange={(e) => handleChange(e)}
              label={t('contact.company_name')}
            />
            <CustomInput
              errorMessage={
                !formData.email && validation
                  ? t('contact.error_message_required')
                  : ''
              }
              name={'email'}
              onChange={(e) => handleChange(e)}
              label={t('contact.email_address')}
            />
          </div>
          <div className="contact-us-input-wrapper">
            <CustomInput
              errorMessage={
                !formData.subject && validation
                  ? t('contact.error_message_required')
                  : ''
              }
              name={'subject'}
              onChange={(e) => handleChange(e)}
              label={t('contact.subject')}
            />
            <CustomInput
              errorMessage={
                !formData.message && validation
                  ? t('contact.error_message_required')
                  : ''
              }
              textarea={mQuery ? true : false}
              name={'message'}
              onChange={(e) => handleChange(e)}
              label={t('contact.message')}
            />
          </div>
        </div>
        <div className="contact-us-inerest-title">
          {t('contact.points_of_interest')}
        </div>
        <div className="contact-us-interest-wrapper">
          <CustomButton
            checked={checkedStatus('groovy_box')}
            onClick={() => handlePointsOfInterests('groovy_box')}
          >
            {t('contact.groovy_box')}
          </CustomButton>
          <CustomButton
            checked={checkedStatus('groovy_system')}
            onClick={() => handlePointsOfInterests('groovy_system')}
          >
            {t('contact.groovy_system')}
          </CustomButton>
          <CustomButton
            checked={checkedStatus('smart_heating')}
            onClick={() => handlePointsOfInterests('smart_heating')}
          >
            {t('contact.smart_heating')}
          </CustomButton>
          <CustomButton
            checked={checkedStatus('custom_solutions')}
            onClick={() => handlePointsOfInterests('custom_solutions')}
          >
            {t('contact.custom_solutions')}
          </CustomButton>
          <div
            className={`contact-us-send-now-button ${deliverStatus}`}
            onClick={() => sendMail()}
          >
            {renderButtonIcon()}
            {renderButtonText()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
