import { Box, Container } from '@mui/material'
import { footer_links_default, footer_bg_color } from '../../util/colors'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FooterButton } from './Footer.styles'

const Footer = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('translations')

  return (
    <Container
      sx={{
        backgroundColor: footer_bg_color,
        color: footer_links_default,
      }}
      disableGutters
      maxWidth={false}
    >
      {/* Mobile */}
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        gap={'1rem'}
        pt={'3rem'}
        pb={'4rem'}
        sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}
      >
        <FooterButton onClick={() => navigate('/terms-of-use')}>
          {t(`footer.terms_of_use`)}
        </FooterButton>
        <FooterButton onClick={() => navigate('/about')}>
          {t(`footer.about`)}
        </FooterButton>
        <FooterButton onClick={() => navigate('/privacy-policy')}>
          {t(`footer.privacy_policy`)}
        </FooterButton>
        <FooterButton onClick={() => navigate('/cookie-policy')}>
          {t(`footer.cookie_policy`)}
        </FooterButton>
        <FooterButton sx={{ visibility: 'hidden' }}>placeholder</FooterButton>
        <FooterButton>{t(`footer.powered_by`)} krtech.digital</FooterButton>
      </Box>

      {/* Desktop */}
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'2.4rem'}
        py={'1.8rem'}
        mx="6rem"
        sx={{ display: { xs: 'none', md: 'flex' } }}
      >
        <FooterButton onClick={() => navigate('/terms-of-use')}>
          {t(`footer.terms_of_use`)}
        </FooterButton>
        <FooterButton onClick={() => navigate('/about')}>
          {t(`footer.about`)}
        </FooterButton>
        <FooterButton onClick={() => navigate('/privacy-policy')}>
          {t(`footer.privacy_policy`)}
        </FooterButton>
        <FooterButton onClick={() => navigate('/cookie-policy')}>
          {t(`footer.cookie_policy`)}
        </FooterButton>
        <FooterButton sx={{ marginLeft: 'auto' }}>
          {t(`footer.powered_by`)} krtech.digital
        </FooterButton>
      </Box>
    </Container>
  )
}

export default Footer
