import { SenzorixLandingPage } from './pages/SenzorixLandingPage/SenzorixLandingPage'
import { PrivacyPolicy } from './pages/PrivacyPolicy/PrivacyPolicy'
import { TermsOfUse } from './pages/TermsOfUse/TermsOfUse'
import { ShippingPolicy } from './pages/ShippingPolicy/ShippingPolicy'
import { RefundPolicy } from './pages/RefundPolicy/RefundPolicy'
import { CookiePolicy } from './pages/CookiePolicy/CookiePolicy'
import { Legal } from './pages/Legal/Legal'
import { About } from './pages/About/About'
import { ProductGuaranty } from './pages/ProductGuaranty/ProductGuaranty'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ScrollToAnchor, ScrollToTop } from './util/util'
import './App.css'
import './colors.css'
import { useRef } from 'react'
import { Footer, Header } from './components'
import { Box } from '@mui/material'

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Roboto',
      textTransform: 'none',
    },
  },
})

function App() {
  const ref = useRef('')

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop />
          <ScrollToAnchor ref={ref} />

          <Header ref={ref} />

          <Routes>
            <Route path="/" element={<SenzorixLandingPage />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/shipping-policy" element={<ShippingPolicy />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/product-guaranty" element={<ProductGuaranty />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/about" element={<About />} />
            <Route path="*" element={<div>doesnt exist component</div>} />
          </Routes>

          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </Box>
  )
}

export default App
