export const PrivacyPolicy = () => {
  return (
    <div className="policy-main-container">
      <div className="policy-title">PRIVACY POLICY</div>
      <div className="policy-text">
        This Privacy Policy explains the type, scope and purpose of the
        processing of your personal information, within our website, services,
        and products, as well as to consumer devices you register with our
        service and to the online application (“App”) which provide support for
        those consumer devices (collectively, our “service”).
      </div>
      <div className="policy-text">
        Person in charge/controller: KRTECH DIGITAL Ltd.
      </div>
      <div className="policy-text">
        Glavna 24, 40326 Sveta Marija (Međimurje), Croatia
      </div>
      <div className="policy-text">email address: info@krtech.digital</div>
      <div className="policy-content-header">Content:</div>
      <div className="policy-subtitle-container">
        <div className="policy-subtitle-header">
          1. Applicability of this Policy
        </div>
        <div className="policy-subtitle-header">2. Types of cookies we use</div>
        <div className="policy-subtitle-header">3. You can manage cookies</div>
        <div className="policy-subtitle-header">4. Relevant legal basis</div>
        <div className="policy-subtitle-header">
          5. Consent to international data transfers
        </div>
        <div className="policy-subtitle-header">
          6. Security and storage of information
        </div>
        <div className="policy-subtitle-header">
          7. What personal information we collect about you and how we use your
          private information
        </div>
        <div className="policy-subtitle-header">
          8. Who else can access your personal information
        </div>
        <div className="policy-subtitle-header">
          9. Your rights and choices regarding your information
        </div>
        <div className="policy-subtitle-header">
          10. Updates to this Privacy policy
        </div>
      </div>

      <div className="policy-subtitle">1. Applicability of this Policy</div>
      <div className="policy-text">
        This Policy covers our treatment of information (hereinafter: personal
        data) that is gathered, including, for example, your name, address,
        email address, billing information, or mobile/telephone number.
      </div>
      <div className="policy-text">
        This Policy does not apply to information collected by third party
        websites or services or to information collected by third parties. We
        have no control over and no responsibility or liability for any
        third-party collection, use, disclosure, or retention of your
        information and that collection, use, disclosure, and retention is not
        subject to this Policy. Please review the privacy policies of any
        websites you may access.
      </div>

      <div className="policy-subtitle">2. Definitions</div>
      <div className="policy-text">
        Personal data / personal information - all data relating to an
        identified natural person or an identifiable natural person.
      </div>
      <div className="policy-text">
        Processing - any action or set of actions performed on personal data or
        a set of personal data.
      </div>
      <div className="policy-text">
        Controller - the natural or legal person, public authority, agency or
        other body which, alone or jointly with others, determines the purposes
        and means of the processing of personal data.
      </div>
      <div className="policy-text">
        Data carrier - a natural person whose personal data is processed.
      </div>
      <div className="policy-text">
        Child - a natural person under 16 years of age.
      </div>

      <div className="policy-subtitle">
        3. Categories of persons affected by processing
      </div>
      <div className="policy-text">
        KRTECH DIGITAL Ltd. does not intentionally collect or maintain
        information from persons under 16 years of age. If you become aware that
        personal information about a person under 16 years of age has been
        provided to us, please immediately contact us using the details above.
      </div>
      <div className="policy-text">
        Controller - the natural or legal person, public authority, agency or
        other body which, alone or jointly with others, determines the purposes
        and means of the processing of personal data.
      </div>
      <div className="policy-text">
        Persons which are affected by personal information processing are
        customers, interested parties, visitors, users of online offering and
        business partners.
      </div>
      <div className="policy-text">
        There is no processing of special categories of personal information in
        accordance with Article 9 paragraph 1 GDPR.
      </div>

      <div className="policy-subtitle">4. Relevant legal basis</div>
      <div className="policy-text">
        The legal basis for the processing of personal data are:
      </div>
      <div className="policy-text">
        Article 6 (1) (a) GDPR, and Article 7 GDPR for obtaining consent from
        data carrier;
      </div>
      <div className="policy-text">
        Article 6 (1) (b) GDPR for performance of our services or implementation
        of contractual measures and answering the inquiries;
      </div>
      <div className="policy-text">
        Article 6 (1) (c) GDPR for fulfillment of our legal obligations;
      </div>
      <div className="policy-text">
        Article 6 (1) (f) GDPR for the purposes of our legitimate interests.
      </div>

      <div className="policy-subtitle">
        5. Consent to international data transfers
      </div>
      <div className="policy-text">
        Your personal information may be collected, processed and stored by
        KRTECH DIGITAL Ltd. or our service providers in the Republic of Croatia
        and other countries where our servers reside. Please be aware that the
        privacy protections and legal requirements, including the rights of
        authorities to access your personal information, in some of these
        countries may not be equivalent to those in your country.
      </div>
      <div className="policy-text">
        If your personal information are processed in a third country (i.e.
        outside the European Union or the European Economic Area), or this takes
        place in the context of the use of third party services, it is only done
        to fulfill our (pre-)contractual obligations, on the basis of your
        consent, on the basis of a legal obligation or on the basis of our
        legitimate interests. Subject to legal or contractual permissions, we
        only process the data in a third country if the special requirements of
        Article 44 GDPR are met. The processing is carried out, for example, on
        the basis of special guarantees, such as the officially recognized
        determination of a data protection level corresponding to the EU (e.g.
        for the USA through the “EU-US Data Privacy Framework”).
      </div>

      <div className="policy-subtitle">
        6. Security and storage of information
      </div>
      <div className="policy-text">
        In accordance with Article 25 and Article 32 GDPR, taking into account
        the state of the art, the costs of implementation and the nature, scope,
        context and purposes of processing as well as the risk of varying
        likelihood and severity for the rights and freedoms of natural persons,
        KRTECH DIGITAL Ltd. takes appropriate technical and organizational
        measures to ensure a level of security of processing. Appropriate
        technical and organizational measures include in particular the ability
        to ensure the ongoing confidentiality, integrity, availability and
        resilience of processing systems and services and encrypted transmission
        of data between your browser and our server.
      </div>
      <div className="policy-text">
        However, no security system is impenetrable and we cannot fully
        guarantee security of our systems.
      </div>
      <div className="policy-text">
        In the event that any information under our control is compromised as a
        result of a breach of security, we will take reasonable efforts to
        investigate the situation and where appropriate, notify those
        individuals whose information may have been compromised and take other
        steps, in accordance with any applicable laws and regulations. If you
        wish to change your password, or if you become aware of any loss, theft
        or unauthorized use of your password, please contact us at
        info@krtech.digital.
      </div>
      <div className="policy-text">
        KRTECH DIGITAL Ltd. may process your personal information on servers
        that are not in your home country. You consent to the transfer of your
        personal information to the Republic of Croatia or any other geographic
        location. If you are accessing the website or service from outside the
        Republic of Croatia, please be advised that Croatian law or the law of
        European Union or any other jurisdiction where we or our service
        providers process your personal information may not offer the same
        privacy protections as the law of your jurisdiction, and in certain
        circumstances, your personal information may be accessible to law
        enforcement and regulatory authorities in accordance with the laws of
        these other jurisdictions.
      </div>

      <div className="policy-subtitle">
        7. What personal information we collect about you and how we use your
        private information
      </div>
      <div className="policy-text">
        We collect personal information in order to provide you with a
        personalized, useful and efficient experience. The categories of
        information we collect can include:
      </div>
      <div className="policy-text-type">The information you provide us</div>
      <div className="policy-text">
        Such as your email address, name, surname, username, shipping address,
        billing information, phone number.
      </div>
      <div className="policy-text">
        If you register your device online, through the mail, or via other
        registration means, we also collect information about your product, such
        as the device ID, serial number or product code.
      </div>
      <div className="policy-text">
        We store information so that you can comment on or perform other
        activities on the website.
      </div>
      <div className="policy-text-type">
        Information that is collected automatically
      </div>
      <div className="policy-text">
        This includes information that is automatically stored via cookies or
        other session tools. For example, your web requests, Internet Protocol
        (“IP”) address, browser type, information about your mobile device,
        referring / exit pages and URLs, number of clicks, domain names, search
        history on landing or viewed pages, and other such information. This
        data is used to improve the user experience.
      </div>
      <div className="policy-text">
        We automatically collect certain types of usage information when you
        visit our website or use our services. In such cases, we may send one or
        more cookies — small text files containing a string of alphanumeric
        characters that are stored on users’ computer and are used to store
        information about a user during or after their visit to an online
        offering.
      </div>
      <div className="policy-text">
        Cookie uniquely identifies your browser and lets us help you log in
        faster and enhance your navigation through the site. Cookies themselves
        do not require personal information to function and, in most cases, do
        not personally identify internet users.
      </div>
      <div className="policy-text">
        We may use the data collected through cookies, log file, device
        identifiers, location data and clear gifs information to:
      </div>
      <div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">•</div>
          <div>
            remember information so that you will not have to re-enter it during
            your visit or the next time you visit the websites;
          </div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">•</div>
          <div>provide and serve custom, personalized advertising;</div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">•</div>
          <div>provide and monitor the effectiveness of our services;</div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">•</div>
          <div>
            monitor aggregate metrics such as total number of visitors, traffic,
            usage, and demographic patterns on our website and the Services;
          </div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">•</div>
          <div>diagnose or fix technology problems; and</div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">•</div>
          <div>otherwise to plan for and enhance our Service.</div>
        </div>
      </div>
      <div className="policy-text">
        We use temporary and permanent cookies. Temporary cookies or Session
        Cookies are cookies that are deleted after a user leaves an online
        service and closes his browser. Those cookies can, for example, store
        the login status or contents of a shopping cart in an online shop.
        Permanent cookies remain stored until they expire, even after the
        browser is closed. Such a cookie can store interest of users, which are
        used for marketing purposes.
      </div>
      <div className="policy-text">
        If you do not want cookies to be stored on your computer, you can
        deactivate the corresponding option in the system settings of your
        browser. Also, stored cookies can be deleted in the system settings of
        your browser. Anyhow, the exclusion of cookies can lead to functional
        restrictions of our online offer and services.
      </div>
      <div className="policy-text">
        You can object to the use of cookies used for many network advertising
        programs that track your activities across multiple websites to deliver
        personalized advertising content to you by visiting:
        <div className="policy-info-text-wrapper">
          <div>
            - European Union:{' '}
            <a href="http://www.youronlinechoices.com/">
              http://www.youronlinechoices.com/
            </a>
          </div>
        </div>
        <div className="policy-info-text-wrapper">
          <div>
            - United States:{' '}
            <a href="http://www.aboutads.info/choices/">
              http://www.aboutads.info/choices/
            </a>
          </div>
        </div>
      </div>
      <div className="policy-text">
        More information about the use of cookies you can find{' '}
        <a href="https://groovybox.co/cookie-policy">here</a>.
      </div>
      <div className="policy-text-type">
        Information we receive from third parties
      </div>
      <div className="policy-text">
        If you interact with our website or Apps through various social media,
        or access our service through a third-party connection or log in, we may
        receive information from the social network such as your profile
        information, profile picture, gender, user name, user ID associated with
        your social media account, age range, country, friends list, and any
        other information you permit the social network to share with third
        parties. The data we receive is dependent upon your privacy settings
        with the social network. You should always review, and if necessary,
        adjust your privacy settings before linking or connecting them to our
        website or service. We may use this information to operate, maintain,
        and provide to you the features and functionality of the service, to
        process and ship orders, to provide customer service, and to communicate
        directly with you, such as to send you information about your orders,
        product sales, special offers, and new site features. You can control
        your receipt of email by visiting your Account page on our website or
        through the App. We may also send you Service-related emails or
        notifications (e.g., account verification, order confirmations, change
        or updates to features of the service, technical and security notices).
        You may not be able to opt-out of Service-related e-mails.
      </div>
      <div className="policy-text">
        The services may, from time to time, contain links to and from third
        party websites of our partner networks, advertisers, partner merchants,
        retailers and affiliates. If you follow a link to any of these websites,
        please note that these websites have their own privacy policies and that
        we do not accept any responsibility or liability for their policies.
      </div>
      <div className="policy-text-type">
        Information we receive from third parties
      </div>
      <div className="policy-text">
        Our product is equipped with smart technology which allows it to
        transmit data wirelessly to our service. These data include information
        about product’s function and use statistics, such as battery life and
        health, number of missions, the device identifier, and location mapping.
        When you register your product with the online App, the App will collect
        and maintain information about the product and/or App usage, feature
        usage, in-App transactions, technical specifications, crashes, and other
        information about how you use your Product and the product App. We may
        also collect information provided during set up. We use this information
        to collect and analyze statistics and usage data, diagnose and fix
        technology problems, enhance device performance, and improve user
        experience. We may use this information to provide you personalized
        communications, including marketing and promotional messages, such as
        emails providing product order information. Our product do not transmit
        this information unless you register your device online and connect to
        WiFi, Bluetooth, or connect to the internet via another method. It is
        possible to use our smart technology product without WiFi or Bluetooth
        data transmission by disconnecting your WiFi or Bluetooth from the
        device or by never connecting it at all.
      </div>
      <div className="policy-subtitle">
        8. Who else can access your personal information
      </div>
      <div className="policy-text">
        We may share your personal information with:
      </div>
      <div className="policy-text">
        a/ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Third party vendors, affiliates, and
        other service providers that perform services on our behalf, solely in
        order to carry out their work for us (e.g. identifying and serving
        targeted advertisements, providing e-commerce services, content or
        service fulfillment, billing, web site operation, payment processing and
        authorization, customer service, or providing analytics services). For
        additional information on the way in which these organizations treat
        your personal information, you may contact us.
      </div>
      <div className="policy-text">
        b/ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;With your consent, to third parties who
        we think may offer you products or services you may enjoy;
      </div>
      <div className="policy-text">
        c/ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Third parties at your request. (e.g.
        you may have the option to Like or Follow us on social media and that
        action may be posted to your timeline, visible to your friends or
        followers or otherwise shared publicly.)
      </div>
      <div className="policy-text">
        d/ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Third parties as required to:
      </div>
      <div className="policy-bullet-container">
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">-</div>
          <div>
            investigate and defend ourselves against any third party claims or
            allegations;
          </div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">-</div>
          <div>
            perform any company transaction such as a merger, sale of all or a
            portion of company{' '}
          </div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">-</div>
          <div>
            assets or shares, reorganization, financing, change of control or
            acquisition of all or a portion of our business by another company
            or third party or in the event of bankruptcy or related or similar
            proceedings.
          </div>
        </div>
      </div>
      <div className="policy-text">
        <strong>Disclaimer.</strong> We cannot ensure that all of your
        information will never be disclosed in ways not otherwise described in
        this Policy. For example, a third party may unlawfully intercept or
        access transmissions or private communications, or other users of the
        App may abuse or misuse your personally identifiable information. No
        transmission of data over the internet can be fully secure.
      </div>
      <div className="policy-subtitle">
        9. Your rights and choices regarding your information
      </div>
      <div className="policy-text">You have the right to:</div>
      <div className="policy-bullet-container">
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">•</div>
          <div>
            withdraw the consent at any time, in accordance with Article 7 (3)
            GDPR
          </div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">•</div>
          <div>
            obtain confirmation as to whether or not your personal data are
            being processed. This includes the right to access your personal
            information, in accordance with Article 15 GDPR.
          </div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">•</div>
          <div>
            obtain the rectification of inaccurate personal data and also the
            right to have incomplete personal data completed, in accordance with
            Article 16 GDPR.
          </div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">•</div>
          <div>
            obtain the erasure of personal data without undue delay, in
            accordance with Article 17 GDPR.
          </div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">•</div>
          <div>
            obtain restriction of processing, in accordance with Article 18
            GDPR.
          </div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">•</div>
          <div>
            receive your personal data in a structured, commonly used and
            machine-readable format and to transmit those data to another
            controller, in accordance with Article 20 GDPR
          </div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">•</div>
          <div>
            object to processing of your personal data at any time, for example
            in the case of direct marketing and advertising purposes, in
            accordance with Article 21 GDPR.
          </div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">•</div>
          <div>
            lodge a complaint with a supervisory authority of Republic of
            Croatia, which is the Agency for the Protection of Personal Data
            (AZOP) via e-mail: <a href="azop@azop.hr">azop@azop.hr</a>, in
            accordance with Article 77 GDPR
          </div>
        </div>
      </div>
      <div className="policy-subtitle">10. Updates to this Privacy policy</div>
      <div className="policy-text">
        We reserve the right to prospectively update this Policy from time to
        time, in our sole discretion. We advise you to regularly inform yourself
        about the content of this Policy for changes and you can review the most
        current Policy any time. We will inform you as soon as the changes of
        this Privacy Policy require your cooperation (e.g. consent) or as soon
        as any other individual notification is required. This Policy was last
        revised on, and effective as of [January 23th, 2024.]
      </div>
    </div>
  )
}
