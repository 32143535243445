import { Button, IconButton, Stack } from '@mui/material'
import {
  main_menu_bg_color,
  main_menu_link_active,
  main_menu_link_hover_color,
  main_menu_link_text_color,
} from '../../util/colors'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg'
import { ReactComponent as GermanFlag } from '../../assets/GermanFlag.svg'
import { ReactComponent as GermanFlagDisabled } from '../../assets/GermanFlagDisabled.svg'
import { ReactComponent as BritishFlag } from '../../assets/BritishFlag.svg'
import { ReactComponent as BritishFlagDisabled } from '../../assets/BritishFlagDisabled.svg'
import { ReactComponent as SenzorixLogo } from '../../assets/SenzorixLogo.svg'
import { handleLanguage } from '../../util/util'

export const pages = ['home', 'products', 'custom_solutions', 'contact']
export const sectionIds = [
  'description',
  'products',
  'custom-solutions',
  'contact',
]
export const iconIds = ['e', 'eD', 'g', 'gD']

export const desktopStyle = {
  flexGrow: 0,
  display: { xs: 'none', md: 'flex' },
  gap: '0.8rem',
  pr: '1rem',
  alignItems: 'center',
}

export const DynamicIcon = ({
  isActive,
  Icon,
  DisabledIcon,
  onClick,
  sx,
  id,
}) => {
  return (
    <IconButton
      disableRipple
      id={id}
      sx={{ my: 'auto', p: '0.5rem 1rem', ml: '0rem', ...sx }}
      onClick={onClick}
    >
      {isActive ? (
        <>
          <Icon></Icon>
        </>
      ) : (
        <>
          <DisabledIcon></DisabledIcon>
        </>
      )}
    </IconButton>
  )
}

const SliderButton = ({ children, onClick }) => {
  return (
    <Button
      onClick={onClick}
      disableRipple
      sx={{
        color: main_menu_link_text_color,
        minWidth: 'unset',
        px: '1rem',
        border: '1px solid transparent',
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        borderRadius: 0,
        ':hover': {
          backgroundColor: main_menu_link_hover_color,
          color: main_menu_link_text_color,
        },
        ':active': {
          backgroundColor: 'unset',
          color: main_menu_link_active,
        },
      }}
    >
      {children}
    </Button>
  )
}

export const Slider = forwardRef(
  ({ sx, handleClose, englishActive, setEnglishActive, navigate }, ref) => {
    const { t } = useTranslation('translations')
    const { ref: reference, sliderRef } = ref

    return (
      <Stack
        alignItems={'flex-end'}
        gap={'1rem'}
        width={1}
        zIndex={10}
        sx={{
          pb: 2,
          pt: 4,
          backgroundColor: main_menu_bg_color,
          ...sx,
        }}
        ref={sliderRef}
      >
        <Stack
          width={1}
          direction={'row'}
          justifyContent={'space-around'}
          mb={'2rem'}
        >
          <SenzorixLogo
            style={{
              marginRight: 'auto',
              marginLeft: '1.4rem',
              overflow: 'visible',
            }}
            onClick={() => {
              navigate('/')
              ref.current = null
            }}
          ></SenzorixLogo>
          <IconButton
            onClick={handleClose}
            sx={{
              p: '0.25rem 0.5rem',
              mr: '1rem',
              borderRadius: 0,
              ':hover': {
                backgroundColor: main_menu_link_hover_color,
              },
            }}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
        </Stack>

        {pages.map((page, index) => {
          return (
            <SliderButton
              key={index}
              onClick={() => {
                handleClose(true)
                reference.current = sectionIds[index]
                navigate(`/`)
              }}
            >
              {t(`header.${page}`)}
            </SliderButton>
          )
        })}
        <DynamicIcon
          id={'en'}
          isActive={englishActive}
          Icon={BritishFlag}
          DisabledIcon={BritishFlagDisabled}
          onClick={(e) => {
            handleLanguage({ lang: 'en', setEnglishActive })
          }}
        ></DynamicIcon>
        <DynamicIcon
          id={'de'}
          isActive={!englishActive}
          Icon={GermanFlag}
          DisabledIcon={GermanFlagDisabled}
          onClick={(e) => {
            handleLanguage({ lang: 'de', setEnglishActive })
          }}
        ></DynamicIcon>
      </Stack>
    )
  }
)

export const CustomButton = ({ onClick, sx, children }) => {
  return (
    <Button
      onClick={onClick}
      disableRipple
      sx={{
        ...sx,
        borderRadius: 0,
        border: '1px solid transparent',
        color: main_menu_link_text_color,
        display: 'block',
        fontWeight: 500,
        fontSize: '0.875rem',
        p: '0.2rem 1rem',
        minWidth: 'unset',
        minHeight: '0rem',
        ':hover': {
          backgroundColor: main_menu_link_hover_color,
          color: main_menu_link_text_color,
        },
        ':active': {
          backgroundColor: 'unset',
          color: main_menu_link_active,
        },
      }}
    >
      {children}
    </Button>
  )
}
