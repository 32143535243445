import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, Switch } from '@mui/material'
import { applyGoogleAnalytics, applyHotjar } from '../../util/cookies'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import './cookie_dialog.css'

const CookieDialog = () => {
  const [settings, setSettings] = useState(false)
  const [displayDialog, setDisplayDialog] = useState(true)
  const [analyticsCookies, setAnalyticsCookies] = useState(true)
  const [marketingCookies, setMarketingCookies] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const cookies = new Cookies()
    let cookieConfig = cookies.get('cookieConfigSenzorix')
    if (cookieConfig) {
      setDisplayDialog(false)
    }
  }, [])

  const acceptAll = () => {
    const cookies = new Cookies()
    cookies.set(
      'cookieConfigSenzorix',
      JSON.stringify({ analytics: true, marketing: true })
    )
    applyGoogleAnalytics()
    applyHotjar()
    setDisplayDialog(false)
  }

  const confirmSelected = () => {
    const cookies = new Cookies()
    cookies.set(
      'cookieConfigSenzorix',
      JSON.stringify({
        analytics: analyticsCookies,
        marketing: marketingCookies,
      })
    )
    if (analyticsCookies) {
      applyGoogleAnalytics()
      applyHotjar()
    }
    setDisplayDialog(false)
  }

  return (
    <Dialog
      PaperProps={{
        style: {
          margin: '2rem 0 0 0',
          alignSelf: 'baseline',
        },
      }}
      open={displayDialog}
    >
      {settings ? (
        <div>
          <DialogTitle>
            <div className="cookie-consent-header-container">
              Essential cookies
              <Switch defaultChecked disabled />
            </div>
            <div className="cookie-consent-info">
              Essential cookies allow you to navigate through our website and
              use our services and functions. Without these files, our website
              will not be able to work effectively.
            </div>
            <div className="cookie-consent-header-container">
              Analytics cookies
              <Switch
                defaultChecked
                onChange={(e) => setAnalyticsCookies(e.target.checked)}
              />
            </div>
            <div className="cookie-consent-info">Google Analytics & Ads</div>
            <div className="cookie-consent-info">GoogleTag Manager</div>
            <div className="cookie-consent-info">Hotjar</div>
            <div className="cookie-consent-header-container">
              Marketing cookies
              <Switch
                defaultChecked
                onChange={(e) => setMarketingCookies(e.target.checked)}
              />
            </div>
            <div className="cookie-consent-info">Facebook</div>
            <div className="cookie-consent-info">Geotargeting</div>
          </DialogTitle>
          <DialogContent className="cookie-consent-button-container">
            <div
              className="cookie-consent-settings-button"
              onClick={() => confirmSelected()}
            >
              Confirm Selected
            </div>
            <div
              className="cookie-consent-accept-button"
              onClick={() => acceptAll()}
            >
              Accept All
            </div>
          </DialogContent>
        </div>
      ) : (
        <div>
          <DialogTitle>
            The website uses cookies to ensure a better user experience and
            functionality of the pages. More information about cookies are
            available{' '}
            <button
              className="cookie-consent-link"
              onClick={() => navigate('/cookie-policy')}
            >
              here
            </button>
            .
          </DialogTitle>
          <DialogContent className="cookie-consent-button-container">
            <div
              className="cookie-consent-accept-button"
              onClick={() => acceptAll()}
            >
              Accept All
            </div>
            <div
              className="cookie-consent-settings-button"
              onClick={() => setSettings(true)}
            >
              Settings
            </div>
          </DialogContent>
        </div>
      )}
    </Dialog>
  )
}

export default CookieDialog
