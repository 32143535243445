import { Box, Container } from '@mui/material'
import './index.css'
import { foundation_grey_g300 } from '../../util/colors'
export const ShippingPolicy = () => {
  return (
    <div className="container">
      <Container
        sx={{
          color: foundation_grey_g300,
          py: '5rem',
        }}
        maxWidth="lg"
      >
        <Box display={'flex'} flexDirection={'column'} gap={'2rem'}>
          <div style={{ fontSize: '2rem', fontWeight: 500 }}>
            ShippingPolicy
          </div>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'2rem'}
            style={{ lineHeight: '142%' }}
          >
            <div>
              This Privacy Policy explains the type, scope and purpose of the
              processing of your personal information, within our website,
              services, and products, as well as to consumer devices you
              register with our service and to the online application (“App”)
              which provide support for those consumer devices (collectively,
              our “service”).
            </div>
            <div>Person in charge/controller: KRTECH DIGITAL Ltd.</div>
          </Box>
        </Box>
      </Container>
    </div>
  )
}
