import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AddIcon } from '../../assets/AddIcon.svg'
import { ReactComponent as EqualIcon } from '../../assets/EqualIcon.svg'
import './custom_solutions.css'

const CustomSolutions = () => {
  const { t } = useTranslation('translations')
  return (
      <div id="custom-solutions" className="custom-solutions-container">
        <div className="custom-solutions-display-wrapper">
          <div className="custom-solutions-processor-image" />
          <div>
            <div className="custom-solutions-title">
              {t('custom_solutions.title')}
            </div>
            <div className="custom-solutions-image-wrapper">
              <div className="custom-solutions-image product">
                <div className="custom-solutions-image-text">
                  {t('custom_solutions.image.text1')}
                </div>
              </div>
              <div className="custom-solutions-operator-image">
                <AddIcon />
              </div>
              <div className="custom-solutions-image upgrade">
                <div className="custom-solutions-image-text">
                  {t('custom_solutions.image.text2')}
                </div>
              </div>
              <div className="custom-solutions-operator-image">
                <EqualIcon />
              </div>
              <div className="custom-solutions-image solutions">
                <div className="custom-solutions-image-text">
                  {t('custom_solutions.image.text3')}
                </div>
              </div>
            </div>
            <div className="custom-solutions-body-text">
              {t('custom_solutions.text')}
            </div>
          </div>
        </div>
      </div>
  )
}

export default CustomSolutions
