import './terms_of_use.css'
export const TermsOfUse = () => {
  return (
    <div className="terms-main-container">
      <div className="terms-title">
        GENERAL TERMS AND CONDITIONS (TERMS OF USE)
      </div>
      <div className="terms-subtext">
        By visiting our site and/or purchasing products from our Shop, you agree
        to be bound by the following terms and conditions (“Terms of Use” and/or
        “Terms”), including those additional terms and conditions and policies
        referenced herein and/or available by hyperlink. These Terms of Use
        apply to all users of the site, including without limitation users who
        are browsers, vendors, customers, consumers, and/or merchants.
      </div>
      <div className="terms-header">Content</div>
      <div className="terms-content-point-container">
        <div className="terms-subtitle-header">1. Scope of application</div>
        <div className="terms-subtitle-header">
          2. Offers and service descriptions
        </div>
        <div className="terms-subtitle-header">
          3. Order process and purchase contract
        </div>
        <div className="terms-subtitle-header">
          4. Prices and shipping costs
        </div>
        <div className="terms-subtitle-header">
          5. Delivery and product availabilit
        </div>
        <div className="terms-subtitle-header">6. Payment terms</div>
        <div className="terms-subtitle-header">7. Retention of title</div>
        <div className="terms-subtitle-header">
          8. Warranty for material defects
        </div>
        <div className="terms-subtitle-header">
          9. Right of Withdrawal (Cooling off period)
        </div>
        <div className="terms-subtitle-header">10. Liability</div>
        <div className="terms-subtitle-header">11. Wording of a contract</div>
        <div className="terms-subtitle-header">12. Dispute resolution</div>
        <div className="terms-subtitle-header">13. Final Provisions</div>
      </div>
      <div className="terms-point-header">
        1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Scope of application
      </div>
      <div className="terms-point">
        1.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For the business relationship between
        KRTECH DIGITAL Ltd. (hereinafter referred to as "Seller") and the
        customer (hereinafter referred to as "Customer"), the following General
        Terms and Conditions shall apply exclusively in the version valid at the
        time of the order.
      </div>
      <div className="terms-point">
        1.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You can contact our service department
        for questions, complaints, and grievances by emailing
        info@krtech.digital.
      </div>
      <div className="terms-point">
        1.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For the purposes of these Terms and
        conditions, a consumer includes every natural person who enters into a
        legal transaction for purposes that predominantly are outside their
        trade, business, or profession.
      </div>
      <div className="terms-point">
        1.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By contrast an entrepreneur is any
        natural or legal person or unincorporated organization who is exercising
        their commercial or independent professional activity, when entering
        into the contract. Both consumer and entrepreneur are hereinafter
        referred to as customers.
      </div>
      <div className="terms-point">
        1.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Customer's alternative terms and
        conditions shall not be acknowledged unless the seller expressly agrees
        to their application.
      </div>
      <div className="terms-point-header">
        2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Offers and service
        descriptions
      </div>
      <div className="terms-point">
        2.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The presentation of the products in
        the seller's online Shop does not constitute a legally binding offer but
        an invitation to place an order. Descriptions of services in catalogs
        and on the seller’s websites do not have the character of an assurance
        or guarantee.
      </div>
      <div className="terms-point">
        2.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All offers are valid as long as stocks
        last unless otherwise stated on the products. If the seller is unable to
        deliver the ordered product (e.g., the ordered products are not
        available), the seller will not issue a declaration of acceptance and/or
        will not send the product. The customer will be informed immediately in
        such cases. Any compensation already received will be refunded by the
        seller without delay.
      </div>
      <div className="terms-point-header">
        3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Order process and
        purchase contract
      </div>
      <div className="terms-point">
        3.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Customers can select products from the
        seller’s assortment without any obligation and collect them in so-called
        shopping cart. The product selection within the shopping cart can be
        changed or deleted. The customer can within the shopping cart complete
        the ordering process by pressing the button.
      </div>
      <div className="terms-point">
        3.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Using the [order with payment] button,
        the customer submits a binding application to purchase the product in
        the shopping cart. Before placing the order, the customer can at any
        time change and view the data and use the browser “back” function to go
        back to the cart or cancel the ordering process altogether.
      </div>
      <div className="terms-point">
        3.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The seller then sends the customer an
        automatic confirmation of receipt by email, in which the customer’s
        order is listed once more and the customer can print the order using the
        “Print” button (order confirmation). The automatic confirmation of
        receipt only demonstrates that the customer’s order has been received by
        the seller and does not constitute acceptance of the order. A contract
        is only concluded if the seller confirms the order by means of a
        declaration of acceptance or—in the event that the seller does not make
        an explicit declaration of acceptance—if the ordered item is sent to the
        buyer by the seller.
      </div>
      <div className="terms-point">
        3.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the seller allows an advance
        payment, the contract is concluded when the bank details and payment
        request are provided. A reservation of the product in the case of
        advance payments is made for a maximum of 10 calendar days. If payment,
        despite the due date and even after the renewed request, has not been
        received by the seller within the 10 calendar days of sending the order
        confirmation or renewed request, the seller withdraws from the contract
        because the order is no longer valid and the seller has no obligation to
        deliver. The order is then completed without any further consequences
        for the customer and seller.
      </div>
      <div className="terms-point-header">
        4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Prices and shipping
        costs
      </div>
      <div className="terms-point">
        4.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All prices that are shown on the
        seller’s website are inclusive of the statutory VAT at the respective
        current applicable rate.
      </div>
      <div className="terms-point">
        4.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In addition to the stated prices, the
        seller charges shipping costs. The shipping costs will be clearly
        communicated to the customer on a separate information page and in the
        framework of the order process.
      </div>
      <div className="terms-point-header">
        5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Delivery and product
        availability
      </div>
      <div className="terms-point">
        5.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If advance payment has been agreed,
        delivery is made after receipt of the invoice amount.
      </div>
      <div className="terms-point">
        5.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the delivery of products fails due
        to the fault of the customer, even despite three attempts to deliver,
        the seller can withdraw from the contract. Any payments made shall be
        reimbursed to the customer without delay.
      </div>
      <div className="terms-point">
        5.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the ordered product is not
        available because the seller is not supplied with the product by his
        supplier through no fault of his own, the seller may withdraw from the
        contract. In this case, the seller shall inform the customer without
        delay and, if applicable, propose to the customer the supply of a
        comparable product. If no comparable product is available or if the
        customer does not request the delivery of a comparable product, the
        seller shall without delay reimburse the customer for a payment already
        received.
      </div>
      <div className="terms-point">
        5.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The customer shall be informed about
        delivery time and delivery limitations (for example restriction of
        deliveries to certain countries) on the seller's information page or
        within the product description.
      </div>
      <div className="terms-point-header">
        6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Delivery and product
        availability
      </div>
      <div className="terms-point">
        6.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The customer can choose from the
        available payment methods during and before the ordering process. The
        available payment methods shall be shown on a separate information page.
      </div>
      <div className="terms-point">
        6.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If third party payment processing
        providers are engaged in the processing of payments, their general terms
        and conditions shall apply.
      </div>
      <div className="terms-point">
        6.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the payment by invoice is possible,
        then the payment shall be made within 15 days of receipt of the product
        and the invoice. For any other payment methods, shall the payment be
        made in advance without any deduction.
      </div>
      <div className="terms-point">
        6.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the payment is due on a fixed date
        in the calendar, the customer is already in default by missing payment
        on the fixed date. In this case, the customer shall be obligated to pay
        the statutory late-payment interest, in accordance with the law of the
        Republic of Croatia.
      </div>
      <div className="terms-point">
        6.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The customer’s obligation to pay
        statutory late-payment interest does not exclude additional charges for
        damages the seller might require from the customer.
      </div>
      <div className="terms-point">
        6.6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The customer shall have the right to
        offset only if his counterclaims have been legally established or
        acknowledged by the seller. The customer shall only exercise the right
        of retention insofar as the claims arise from the same contractual
        relationship.
      </div>
      <div className="terms-point-header">
        7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Retention of title
      </div>
      <div className="terms-point">
        7.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The delivered product remains the
        property of the seller until full payment has been made.
      </div>
      <div className="terms-point">
        7.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the customer is an entrepreneur,
        the seller shall retain ownership of the delivered products until all
        outstanding claims from the business relationship with the customer have
        been settled. The corresponding liens are transferable to third parties.
      </div>
      <div className="terms-point-header">
        8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Warranty for material
        defects
      </div>
      <div className="terms-point">
        8.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The warranty is determined in
        accordance with the provisions of the law of the European Union and
        Republic of Croatia. The warranty applies for two years from the date of
        purchase and it applies to all products that are available in our Shop.
      </div>
      <div className="terms-point-header">
        9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Right of Withdrawal
        (Cooling off period)
      </div>
      <div className="terms-point">
        9.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Consumer in accordance with Article
        1.3. has the right to return purchases made online within 14 days for a
        full refund. However, the 14-day cooling off period does not apply if
        the product has been used, e.g. to grow plants. The consumer may use the
        sample withdrawal form or complete and submit another clear statement.
        If the consumer withdraws from the contract, the seller shall reimburse
        all payments received from the consumer without undue delay and in any
        event, no later than 14 days from the day on which the seller is
        informed about the consumer's decision to withdraw from the contract.
        The seller may refuse to pay the refund until the product has been
        returned. The consumer shall immediately send back or hand over the
        product to the seller, in any event no later than 14 days from the date
        on which they sent notice of their withdrawal from the contract. The
        deadline shall be considered met if the consumer sends back the product
        before the period of 14 days has expired.
      </div>
      <div className="terms-point-header">
        10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Liability
      </div>
      <div className="terms-point">
        10.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Regarding the seller’s liability for
        damages the following liability exclusions and liability limitations
        shall apply, without prejudice to any other statutory requirements.
      </div>
      <div className="terms-point">
        10.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Furthermore, the seller assumes
        liability for ordinarily negligent breaches of essential contractual
        obligations, the breach of which endangers the achievement of the
        purpose of the contract, or the proper fulfillment of a contract, and on
        whose fulfillment the customer regularly relies. In this case, the
        seller’s liability, however, is only limited to damages that are
        foreseeable and typical to this type of contract. The seller is not
        liable for the ordinary negligence of any duties other than those
        specified in the statements above.
      </div>
      <div className="terms-point">
        10.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The limitations above shall not apply
        to any culpable injury to life, body and health, or to the breach of
        essential contractual obligations, as well as to other damages caused by
        an intentional or grossly negligent breach of duty by the seller.
      </div>
      <div className="terms-point">
        10.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Seller’s limitation of liability
        and/or exclusion of liability also applies to his employees.
      </div>
      <div className="terms-point-header">
        11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wording of a contract
      </div>
      <div className="terms-point">
        11.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The customer can print out the
        contract text before placing the order to the seller by using the
        “print” function of his browser in the last step of the order. Moreover,
        the seller’s obligation is to save the contract text, but shall not make
        it available and public on the Internet. The seller shall also send the
        customer an order confirmation containing all the details of the order
        to the email address provided by the customer.
      </div>
      <div className="terms-point">
        11.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the customer has registered in our
        Shop, he can view the placed orders in his profile sector.
      </div>
      <div className="terms-point-header">
        12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dispute resolution
      </div>
      <div className="terms-point">
        12.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The EU Commission provides a platform
        for online dispute resolution at the following link:
        http://ec.europa.eu/consumers/odr.
      </div>
      <div className="terms-point">
        12.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The platform for online dispute
        resolution serves as a contact point for the out-of-court settlement of
        disputes arising from online purchases or service contracts involving a
        consumer. The seller is neither obliged nor willing to participate in
        consumer dispute resolution proceedings.
      </div>
      <div className="terms-point-header">
        13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Final provisions
      </div>
      <div className="terms-point">
        13.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The law of the Republic of Croatia
        shall apply to the exclusion of the UN Convention on Contracts for the
        International Sale of Goods. The statutory provisions on the restriction
        of the choice of law and the applicability of mandatory provisions, in
        particular the state in which the customer has their standard place of
        residence as a consumer, shall remain unaffected.
      </div>
      <div className="terms-point">
        13.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the customer is a merchant, a
        legal entity under public law, or a special fund under public law, the
        place of jurisdiction for all disputes arising from contractual
        relationships between the seller and the customer is the registered
        office of the seller, i.e. the Republic of Croatia. However, the seller
        shall also be entitled to take action against the merchant at the court
        responsible at their residence or place of business.
      </div>
      <div className="terms-point">
        13.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If any provisions of this contract
        are or become invalid, the remaining provisions shall remain unaffected.
      </div>
      <div className="terms-point">
        13.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The seller reserves the right, at his
        sole discretion, to update, change or replace any part of these Terms
        and Conditions by posting updates and changes to his website.
      </div>
      <div className="terms-point">
        13.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This Terms and Conditions were last
        revised on, and effective as of [January 23th, 2024.]
      </div>
    </div>
  )
}
