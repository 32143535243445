import { Button } from '@mui/material'
import { footer_links_hover } from '../../util/colors'

export const FooterButton = ({ children, onClick, sx }) => {
  return (
    <Button
      disableRipple
      sx={{
        zIndex: 0,
        minWidth: 0,
        minHeight: 0,
        p: 0,
        lineHeight: '1.9163rem',
        fontStyle: 'normal',
        color: 'inherit',
        fontSize: '0.875rem',
        letterSpacing: 'unset',
        fontWeight: 300,
        ':hover': {
          color: footer_links_hover,
          backgroundColor: 'unset',
        },
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}
