import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowRigth } from '../../assets/ArrowRight.svg'
import './card.css'

const Card = ({ name, icon, link, disabled }) => {
  const { t } = useTranslation('translations')

  const handleLink = () => {
    if (link) {
      window.open(link, '_blank')
    }
  }
  return (
    <div className={`card-container ${name}-container`}>
      <div className="card-header">{icon}</div>
      <div className="card-text">
        {t(`card.${name.replace('-', '_')}.text`)}
      </div>
      <div className="card-action-container">
        <div
          onClick={() => handleLink()}
          className={`card-button ${name}-button`}
        >
          {!disabled ? t('card.button') : t('card.button-disabled')}
          {!disabled && <div className={`card-arrow-icon-container ${name}-icon-container}`}>
            <ArrowRigth className="card-arrow-icon" />
          </div>}
        </div>
      </div>
    </div>
  )
}

export default Card
