import './policy.css'
export const CookiePolicy = () => {
  const cookieData = [
    {
      name: '_ga',
      description:
        'The main cookie used by Google Analytics, enables a service to distinguish one visitor from another',
      expiry: '2 years',
    },
    {
      name: '_gid',
      description:
        'This period shows the length of the period at which a service can store and/or read certain data from your computer by using a cookie, a pixel, an API, cookieless tracking, or other resources.',
      expiry: '1 day',
    },
    {
      name: 'SID',
      description:
        'Google Analytics customisation cookie. Used by the Google + 1 sharing button and is required to link content to your Google 1+ account.',
      expiry: '2 years',
    },
    {
      name: 'HSID',
      description:
        'Google Analytics customisation cookie. Used by the Google + 1 sharing button and is required to link content to your Google 1+ account.',
      expiry: '2 years',
    },
    {
      name: 'SSID',
      description:
        'Google collects visitor information for videos played out by YouTube, which are played out on integrated maps from Google Maps.',
      expiry: 'Persistent',
    },
    {
      name: 'SAPISID',
      description:
        'Google Analytics customisation cookie. Used by the Google + 1 sharing button and is required to link content to your Google 1+ account.',
      expiry: '2 years',
    },
    {
      name: 'APISID',
      description:
        'Google collects visitor information for videos played out by YouTube.',
      expiry: 'Persistent',
    },
    {
      name: '__Secure-3PAPISID',
      description:
        'Profiles the interests of website visitors to serve relevant and personalised ads through retargeting.',
      expiry: '2 years',
    },
    {
      name: '__Secure-3PSIDCC',
      description:
        'Targeting cookie. Used to create a user profile and display relevant and personalised Google Ads to the user.',
      expiry: '2 years',
    },
    {
      name: 'SIDCC',
      description:
        '  Security cookie to confirm visitor authenticity, prevent fraudulent use of credentials, and protect visitor data from unauthorised access.',
      expiry: '3 months',
    },
    {
      name: 'NID',
      description:
        'Stores visitor preferences and personalises ads on Google sites based on recent searches and interactions.',
      expiry: '6 months',
    },
    {
      name: '_hjSessionUser_site_id',
      description:
        'Ensures data from subsequent visits to the same site are attributed to the same user ID.',
      expiry: '1 year',
    },
    {
      name: '_hjSession_site_id',
      description:
        'Ensures subsequent requests in the session window are attributed to the same session.',
      expiry: 'Persistent',
    },
    {
      name: '_hjIncludedInSessionSample_site_id',
      description:
        "Set to determine if a user is included in the data sampling defined by your site's daily session limit.",
      expiry: 'Persistent',
    },
  ]
  return (
    <div className="policy-main-container">
      <div className="policy-title">COOKIE POLICY</div>
      <div className="policy-content-header">Content:</div>
      <div className="policy-subtitle-container">
        <div className="policy-subtitle-header">
          1. Applicability of this Cookie Policy
        </div>
        <div className="policy-subtitle-header">2. Types of cookies we use</div>
        <div className="policy-subtitle-header">3. You can manage cookies</div>
        <div className="policy-subtitle-header">
          4. Updates to this Cookie policy
        </div>
      </div>
      <div className="policy-subtitle">
        1. Applicability of this Cookie Policy
      </div>
      <div className="policy-text">
        Cookies are small text files containing a string of alphanumeric
        characters that are stored on users’ computer and are used to store
        information about a user during or after their visit to an online
        offering.
      </div>
      <div className="policy-text">
        KRTECH DIGITAL Ltd. uses cookies to improve your user experience on our
        website and to analyze preferences, experience, traffic and marketing.
        You can opt out of using cookies by selecting the appropriate settings
        in the browser or in settings. Please note that if you turn off cookies
        and similar technologies completely, this may affect your user
        experience on the website, and some features might not work as expected.
      </div>
      <div className="policy-subtitle">2. Types of cookies we use</div>
      <div className="policy-text">
        Below you can find a list of different cookie types that can be used on
        our website. This list is not exhaustive, but it is intended to
        illustrate the primary purposes for which we typically set cookies.
      </div>
      <div>
        <div className="policy-text-type">Essential cookies</div>
        <div className="policy-text-m-0">
          Essential or basic cookies allow you to navigate through our site and
          use our services and functions. Without these files, our website will
          not be able to work effectively.
        </div>
      </div>
      <div className="policy-list-container">
        <div className="policy-list-item">
          <div className="policy-list-header-text">cookieConfigSenzorix</div>
          <div className="policy-list-text">
            <div className="policy-list-key">Description:</div>
            Preserves user cookie configuration.
          </div>
          <div className="policy-list-expiry-text">
            <div className="policy-list-key">Expiry:</div>
            Persistent
          </div>
        </div>
      </div>
      <div>
        <div className="policy-text-type">Analytics cookies</div>
        <div className="policy-text-m-0">
          Analytics cookies collect information about your use of the site and
          help us improve its functionality. For example, analytics cookies show
          us the most frequently visited pages on the site as well as analyze
          the site traffic. We use analytics cookies to look at the general
          trends of how people use our website and not how a user interacts with
          the website.
        </div>
      </div>
      <div className="policy-text">
        KRTECH DIGITAL Ltd. uses these platforms for analytics: Google Analytics
        & Ads, Meta Pixel, Google Tag Manager and Hotjar.
      </div>
      <div className="policy-text">
        All above mentioned platforms use cookie technology which provide us
        with different information and anonymous statistics. This services
        operate with IP-addresses, as well as information about the cookies that
        are used on our websites, so we learn how many people visit our website,
        how many of them browse certain pages, what browsers they use (which
        allows us to improve the compatibility of our services for more people)
        and sometimes which countries and regions users live in. This
        information are evaluation of the use of our website, compiling reports
        on the activities of our website, and providing other services.
      </div>
      <div className="policy-list-container">
        {cookieData.map((e, i) => (
          <div className="policy-list-item">
            <div className="policy-list-header-text">{e.name}</div>
            <div className="policy-list-text">
              <div className="policy-list-key">Description:</div>
              {e.description}
            </div>
            <div className="policy-list-expiry-text">
              <div className="policy-list-key">Expiry:</div>
              {e.expiry}
            </div>
          </div>
        ))}
      </div>
      <div>
        <div className="policy-text-type">Marketing cookies</div>
        <div className="policy-text-m-0">
          Marketing cookies are used to target advertising to a user
          (behavioural targeting). They are often served by third party
          companies, and track a user across websites. Ad networks and media
          agencies act as intermediaries for websites and advertisers. The
          marketing cookies of these parties make the following possible:
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">-</div>
          <div>showing you advertisements from third parties</div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">-</div>
          <div>
            combining your preferences with information they collect when you
            visit other websites.
          </div>
        </div>
        <div className="policy-text-m-0">
          For the cookies that these parties place for marketing purposes, we
          refer to the statements on the websites of these parties. The
          following parties place cookies and / or other technologies on our
          website:
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">-</div>
          <div>Facebook</div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">-</div>
          <div>Geotargeting</div>
        </div>
      </div>
      <div className="policy-text-m-0">
        We only use analytics cookies and marketing cookies if you have given
        permission for this. If you do not give permission, analytics cookies
        and marketing cookies shall not be used, but random advertisements can
        still be shown.
      </div>
      <div className="policy-subtitle">3. You can manage cookies</div>
      <div className="policy-text">
        If you do not want cookies to be stored on your computer, you can
        deactivate the corresponding option in the system settings of your
        browser. Also, stored cookies can be deleted in the system settings of
        your browser. You can also refuse the use of cookies in your web
        browser:
      </div>
      <div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">-</div>
          <div>
            Internet Explorer:{' '}
            <a href="http://support.microsoft.com/kb/278835">
              http://support.microsoft.com/kb/278835
            </a>
          </div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">-</div>
          <div>
            Chrome:{' '}
            <a href="http://support.google.com/chrome/bin/answer.py?hl=en-GB&answer=95647">
              http://support.google.com/chrome/bin/answer.py?hl=en-GB&answer=95647
            </a>
          </div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">-</div>
          <div>
            Safari:{' '}
            <a href="https://support.apple.com/en-us/HT201265">
              https://support.apple.com/en-us/HT201265
            </a>
          </div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">-</div>
          <div>
            FireFox:{' '}
            <a href="http://support.mozilla.org/en-US/kb/Enabling%20and%20disabling%20cookies">
              http://support.mozilla.org/en-US/kb/Enabling%20and%20disabling%20cookies
            </a>
          </div>
        </div>
        <div className="policy-bullet-text-container">
          <div className="policy-bullet-point">-</div>
          <div>
            Opera:{' '}
            <a href="http://www.opera.com/browser/tutorials/security/privacy/">
              http://www.opera.com/browser/tutorials/security/privacy/
            </a>
          </div>
        </div>
      </div>
      <div className="policy-text">
        Anyhow, the exclusion of cookies can lead to functional restrictions of
        our online offer and services.
      </div>
      <div className="policy-subtitle">4. Updates to this Cookie policy</div>
      <div className="policy-text">
        This is a brief description of how we use cookies today. If we change
        our use of cookies, we can also change this policy for cookies. We
        advise you to regularly inform yourself about the content of this Policy
        for changes and you can review the most current Policy any time. This
        Policy was last revised on, and effective as of [January 23, 2024.]
      </div>
      <div className="policy-text">
        If you have any questions regarding this policy, please let us know at:
        info@krtech.digital
      </div>
    </div>
  )
}
