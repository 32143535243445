import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { applyGoogleAnalytics, applyHotjar } from './util/cookies.js'
import Cookies from 'universal-cookie'
import './i18n.js'

const injectGA = () => {
  const cookies = new Cookies()
  let cookieConfig = cookies.get('cookieConfigSenzorix')
  if (cookieConfig) {
    let analyticsCookies = cookieConfig.analytics
    if (analyticsCookies) {
      applyGoogleAnalytics()
    } else {
      return
    }
  }
}

const injectHJ = () => {
  const cookies = new Cookies()
  let cookieConfig = cookies.get('cookieConfigSenzorix')
  if (cookieConfig) {
    let analyticsCookies = cookieConfig.analytics
    if (analyticsCookies) {
      applyHotjar()
    } else {
      return
    }
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
    <script>{injectGA()}</script>
    <script>{injectHJ()}</script>
  </React.StrictMode>
)
