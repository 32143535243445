export const foundation_white_w200 = '#FAFAFA'
export const foundation_white_w300 = '#F9F9F9'
export const foundation_white_w75 = '#FDFDFD'
export const foundation_grey_g300 = '#262626'
export const foundation_green2_g200 = '#73BE90'
export const foundation_green2_g500 = '#346C4A'
export const foundation_ligth_green_l400 = '#97A595'
export const foundation_light_green_l500 = '#848F82'
export const foundation_green_g300 = '#61C186'
export const foundation_green_g500 = '#3B7652'

export const main_menu_bg_color = '#1B1B1B'
export const main_menu_link_text_color = '#F4F4F4'
export const main_menu_link_hover_color = '#EA3232'
export const main_menu_link_active = '#E85B5B'

export const footer_bg_color = '#000000'
export const footer_links_default = '#EDEDED'
export const footer_links_hover = '#ED4D4D'

export const white = '#FFFFFF'

export const checkbox_icon_color = '#F4F4F4'
export const checkbox_selected_bg_icon = '#FF4D4D'
