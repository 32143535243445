/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Container from '@mui/material/Container'
import { ReactComponent as SenzorixLogo } from '../../assets/SenzorixLogo.svg'
import { ReactComponent as GermanFlag } from '../../assets/GermanFlag.svg'
import { ReactComponent as GermanFlagDisabled } from '../../assets/GermanFlagDisabled.svg'
import { ReactComponent as BritishFlag } from '../../assets/BritishFlag.svg'
import { ReactComponent as BritishFlagDisabled } from '../../assets/BritishFlagDisabled.svg'
import { ReactComponent as MenuIcon } from '../../assets/MenuIcon.svg'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { main_menu_bg_color } from '../../util/colors'
import { getLanguage, handleLanguage } from '../../util/util'
import {
  CustomButton,
  DynamicIcon,
  Slider,
  desktopStyle,
  iconIds,
  pages,
  sectionIds,
} from './Header.styles'
import { forwardRef } from 'react'
import { useRef } from 'react'

// eslint-disable-next-line no-empty-pattern
const Header = forwardRef(({}, ref) => {
  const [englishActive, setEnglishActive] = useState()
  const [isSliderVisible, setIsSliderVisible] = useState(false)
  const { t } = useTranslation('translations')
  const heightRef = useRef()
  const sliderRef = useRef()
  const hamburgerMenuRef = useRef()

  const navigate = useNavigate()

  const handleCloseSlider = () => {
    setIsSliderVisible(false)
  }

  const handleOpenSlider = () => {
    setIsSliderVisible(true)
  }

  const resizeListener = () => {
    document.documentElement.style.setProperty(
      '--offset-height',
      `${heightRef.current.offsetHeight}px`
    )
  }

  const clickListener = (ev) => {
    if (
      !sliderRef.current.contains(ev.target) &&
      !hamburgerMenuRef.current.contains(ev.target) &&
      !iconIds.find((id) => id === ev.target.parentNode?.id)
    ) {
      handleCloseSlider()
    }
  }

  useEffect(() => {
    getLanguage({ setEnglishActive })

    //scroll behavior differs based on the screen width
    resizeListener()
    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  useEffect(() => {
    //close slider when clicked outside of slider
    window.addEventListener('click', clickListener)

    return () => {
      window.removeEventListener('click', clickListener)
    }
  }, [isSliderVisible])

  return (
    <>
      <AppBar
        className="header"
        sx={{
          backgroundColor: main_menu_bg_color,
          position: { xs: 'relative', md: 'sticky' },
          // boxShadow: { sm: '0px 0px 15px rgba(0, 0, 0, 0.05)' },
          zIndex: 0,
        }}
        elevation={0}
        ref={heightRef}
      >
        <Container
          disableGutters
          maxWidth={false}
          sx={{ my: '0.6rem', px: '0.5rem' }}
        >
          <Toolbar disableGutters>
            <SenzorixLogo
              style={{
                marginRight: 'auto',
                marginLeft: '1.4rem',
                overflow: 'visible',
              }}
              onClick={() => {
                navigate('/')
                ref.current = null
              }}
            ></SenzorixLogo>

            {/* Mobile and Tablet */}
            <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                disableRipple
                onClick={handleOpenSlider}
                ref={hamburgerMenuRef}
                sx={{ mr: '0.5rem' }}
              >
                <MenuIcon></MenuIcon>
              </IconButton>
            </Box>

            {/* PC */}
            <Box sx={desktopStyle}>
              {pages.map((page, index) => (
                <CustomButton
                  key={index}
                  onClick={() => {
                    ref.current = sectionIds[index]
                    navigate(`/`)
                  }}
                >
                  {t(`header.${page}`)}
                </CustomButton>
              ))}
              <DynamicIcon
                isActive={englishActive}
                Icon={BritishFlag}
                DisabledIcon={BritishFlagDisabled}
                onClick={() => {
                  handleLanguage({
                    lang: 'en',
                    setEnglishActive,
                  })
                }}
              ></DynamicIcon>
              <DynamicIcon
                isActive={!englishActive}
                Icon={GermanFlag}
                DisabledIcon={GermanFlagDisabled}
                onClick={() => {
                  handleLanguage({
                    lang: 'de',
                    setEnglishActive,
                  })
                }}
              ></DynamicIcon>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Slider
        sx={{
          display: { md: 'none' },
          position: 'fixed',
          top: 0,
          right: isSliderVisible ? '0%' : '-100%',
          transition: isSliderVisible ? 'right 0.5s ease' : 'right 0.5s ease',
        }}
        englishActive={englishActive}
        setEnglishActive={setEnglishActive}
        handleClose={handleCloseSlider}
        navigate={navigate}
        ref={{ ref, sliderRef }}
      ></Slider>
    </>
  )
})
export default Header
