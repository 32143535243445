import * as React from 'react'
import { useTranslation } from 'react-i18next'
import './description.css'

const Description = () => {
  const { t } = useTranslation('translations')
  return (
    <div id="description" className="description-container">
      <div className="description-title">{t('description.title')}</div>
      <div className="description-text">{t('description.text')}</div>
    </div>
  )
}

export default Description
