import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as GroovyBoxLogo } from '../../assets/GroovyBoxLogo-White.svg'
import { ReactComponent as GroovySystemLogo } from '../../assets/GroovySystemLogo-White.svg'
import { ReactComponent as GroovyControlLogo } from '../../assets/GroovyControlLogo-White.svg'
import Card from '../Card/index'
import './card_grid.css'

const cards = [
  {
    name: 'groovy-box',
    icon: <GroovyBoxLogo className="card-logo" />,
    link: 'https://groovybox.co/',
    disabled: false
  },
  { name: 'groovy-system', icon: <GroovySystemLogo className="card-logo" />, link: '', disabled: true },
  { name: 'groovy-control', icon: <GroovyControlLogo className="card-logo" />, link: '', disabled: true },
]

const CardGrid = () => {
  const { t } = useTranslation('translations')
  return (
    <div id="products" className="card-grid-container">
      <div className="card-grid-title">{t('card_grid.title')}</div>
      <div className="card-grid-wrapper">
        {cards.map((e, i) => (
          <Card key={i} name={e.name} icon={e.icon} link={e.link} disabled={e.disabled} />
        ))}
      </div>
    </div>
  )
}

export default CardGrid
